<template>
  <div class="container">
    <div class="main">
      <yanzheng />
      <div class="zezhao" v-if="zezhao||weihu">
        <div class="zezhao_tip" v-if="zezhao">
          <section class="zz-header">温馨提示</section>
          <div class="zz_content">
            <section style="height:1px"></section>
            <img src="../../assets/closewarn.png" class="closewarn" alt="">
            <h2>{{currentGame.Gamename}}现已关盘</h2>
            <p>下期开盘时间为</p>
            <p class="green">{{kaipanTime}}</p>
          </div>
        </div>
        <div class="zezhao_tip" v-if="weihu">
          <section class="zz-header">温馨提示</section>
          <div class="zz_content">
            <section style="height:1px"></section>
            <img src="../../assets/closewarn.png" class="closewarn" alt="">
            <h2>{{currentGame.Gamename}}正在维护</h2>

          </div>
        </div>

      </div>
      <top-timer></top-timer>
      <div class="cate cm">
        <span :class="title=='二连肖'?'selected':''" @click="changeGameType('二连肖',777,2)">二连肖</span>
        <span :class="title=='三连肖'?'selected':''" @click="changeGameType('三连肖',778,3)">三连肖</span>
        <span :class="title=='四连肖'?'selected':''" @click="changeGameType('四连肖',779,4)">四连肖</span>
        <span :class="title=='五连肖'?'selected':''" @click="changeGameType('五连肖',780,5)">五连肖</span>
        <span :class="title=='二连尾'?'selected':''" @click="changeGameType('二连尾',784,2)">二连尾</span>
        <span :class="title=='三连尾'?'selected':''" @click="changeGameType('三连尾',785,3)">三连尾</span>
        <span :class="title=='四连尾'?'selected':''" @click="changeGameType('四连尾',786,4)">四连尾</span>
        <span :class="title=='五连尾'?'selected':''" @click="changeGameType('五连尾',4022,5)">五连尾</span>
      </div>
      <div class="player listmain listmain-lhc type-lhc bjamlh game-bjamlh game-lhc-ddb">
        <!-- 中间生肖号码 -->
        <div class="data lhc-ddb-data">
          <h3 class="lhc-tuo">
            <span :class="type2 == 'fushi' ? 'selected' : ''" @click="selectedType2('fushi')">复式</span>
            <span :class="type2 == 'dantuo' ? 'selected' : ''" @click="selectedType2('dantuo')">胆拖</span>
            <span :class="type2 == 'duozu' ? 'selected' : ''" @click="selectedType2('duozu')">多组</span>
          </h3>
          <ul class="custom_ul" :style="{height:xiaowei=='xiao'?'204px':'170px'}">
            <li v-for="ele in rowsData[0].arr" :class="{selected:ele.selected,disable:ele.disable } " @click="setCurrentSelected(ele)" :key="ele.ResultID">
              <ol class="td_name">
                {{
                  ele.label
                }}
              </ol>
              <ol class="td_rate">
                {{
                  ele.Odds
                }}
              </ol>
              <ol style="width: 200px" class="td_balls">
                <div v-for="(ball) in ele.balls" :key="ball" :class="colorArr[(Number(ball)-1)] + 'ball b' + ball">
                  {{ ball }}
                </div>
              </ol>
            </li>
          </ul>
          <!-- <ul class="custom_ul1" v-if="xiaowei=='wei'">
            111
            <li v-for="ele in rowsData[0].arr" :class="ele.selected ? 'selected' : ''" @click="setCurrentSelected(ele)" :key="ele.ResultID">
              <ol class="td_name">
                {{
                  ele.label
                }}
              </ol>
              <ol class="td_rate">
                {{
                  ele.Odds
                }}
              </ol>
              <ol style="width: 200px" class="td_balls">
                <div v-for="(ball) in ele.balls" :key="ball" :class="colorArr[(Number(ball)-1)] + 'ball b' + ball">
                  {{ ball }}
                </div>
              </ol>
            </li>
          </ul> -->
        </div>
        <!-- 胆拖 -->
        <div class="data ctuo" v-if="type2 == 'dantuo'">
          <h3>胆拖</h3>
          <ul class="custom_ul3">
            <li v-for="item in dantuoData" :key="item.label" @click="setDanTuo(item)" :class="{disable:item.disable,selected:item.selected}">
              <ol class="td_name">
                {{
                  item.label
                }}
              </ol>
              <!-- <ol style="width: 100px" class="td_chkbox">
                <input type="checkbox" :disabled="item.disable" :checked="item.checked" />
              </ol> -->
            </li>
          </ul>
        </div>
      </div>

      <div class="player listmain tuoduopeng type-lhc bjamlh game-bjamlh game-lhc-ddb">
        <div class="data" v-if="type2=='fushi'">
          <h3>注单号码 <span>共 <em class="red">{{fushiTotalNum}}</em> 种组合 </span></h3>

          <div class="dantuo-sc  red">
            <div>
              <span v-for="(item,index) in fushiData" :key="index">[{{item.join(',')}}]</span>
            </div>
          </div>
        </div>
        <!-- 胆拖 主单 -->
        <div class="data" v-if="type2 == 'dantuo'">
          <h3>注单号码<span>共 <em class="red">{{totalNum}}</em>种组合 </span></h3>
          <div class="flex_dantuo_li">
            <div class="dantuo-li">
              <span>胆码:</span>
              <p v-for="lab in danma" :key="lab.label">[{{lab.label}}]</p>
            </div>
            <div class="dantuo-li">
              <span>拖码:</span>
              <p v-for="lab in tuoma" :key="lab.label">[{{lab.label}}]</p>
            </div>
          </div>

          <div class="dantuo-sc  red">
            <div v-for="(item,index) in dantuoZhuDan" :key="index">
              <span v-for="spa in item" :key="JSON.stringify(spa)">[{{spa.join(',')}}]</span>

            </div>

          </div>
        </div>
        <!-- 多组注单 -->
        <div class="data" v-if="type2 == 'duozu'">
          <h3>注单号码 <span>共 <em class="red">{{duozuTotalNum}}</em> 种组合 </span></h3>

          <div class="dantuo-sc  red">
            <div>
              <span v-for="(item,index) in duozuData" :key="index">[{{item.join(',')}}]</span>

            </div>
          </div>
        </div>
      </div>
      <yushe />
    </div>

    <div class="other">
      <div class="zezhao" v-if="zezhao||weihu"></div>
      <div class="right-com">
        <div class="rc-tab">
          <span :class="changelongActive == '1' ? 'selected' : ''" @click="setLongTab('1')">两面长龙排行</span>
          <!-- <span :class="changelongActive == '2' ? 'selected' : ''" @click="setLongTab('2')">全部长龙</span> -->
        </div>
        <div class="rc-tab-long" v-if="changelongActive=='1'">
          <!-- <div class="long-isbz">
            <span @click="setKaiTab('1')" :class="kaiActive == '1' ? 'selected' : ''">连续开奖</span>
            <span @click="setKaiTab('2')" :class="kaiActive == '2' ? 'selected' : ''">连续未开</span>
          </div> -->
          <ul class="longlist" v-if="kaiActive=='1'">
            <li v-for="item in rclData" :key="item.ID">
              <span>{{item.info}}</span>
              <b class="red">{{item.num}}期</b>
            </li>
          </ul>
          <ul class="longlist" v-if="kaiActive=='2'">
            <li v-for="item in lclData" :key="item.ID">
              <span>{{item.info}}</span>
              <b class="blue">{{item.num}}期</b>
            </li>
          </ul>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import yanzheng from "../yanzheng.vue";
import topTimer from "../mainHeader.vue";
import yushe from "../yushe.vue";
import { mapState } from "vuex";
import { texiaoData, shengxiao } from "../data";
import mixins from "../mixins/mainMixins";
/**
 * 求：组合C(m, n)，m为上标，n为下标。m选n的所有项
 * m {必传} 原始数据
 * n {必传} 当前项还需元素的个数
 * currentIndex 当前索引
 * choseArr 当前项的部分元素集合（不是完整项，是生成完整项的一个中间状态）
 * result 所有项的结果结合
 */
function cmn(m, n, currentIndex = 0, choseArr = [], result = []) {
  let mLen = m.length;
  // 可选数量小于项所需元素的个数，则递归终止
  if (currentIndex + n > mLen) {
    return;
  }
  for (let i = currentIndex; i < mLen; i++) {
    // n === 1的时候，说明choseArr在添加一个元素，就能生成一个新的完整项了。
    // debugger
    if (n === 1) {
      // 再增加一个元素就能生成一个完整项，再加入到结果集合中
      result.push([...choseArr, m[i]]);
      // 继续下一个元素生成一个新的完整项
      i + 1 < mLen && cmn(m, n, i + 1, choseArr, result);
      break;
    }
    // 执行到这，说明n > 2，choseArr还需要两个以上的元素，才能生成一个新的完整项。则递归，往choseArr添加元素
    cmn(m, n - 1, i + 1, [...choseArr, m[i]], result);
  }
  return result;
}
export default {
  name: "",
  props: [""],
  mixins: [mixins],
  data() {
    return {
      groupnames:'lxlw',
      // 胆码
      danma: [],
      danmaOdds: [],
      // 拖码
      tuoma: [],
      tuomaOdds: [],
      dantuoZhuDan: [],
      dantuoOdds: [],
      // 胆拖
      dantuoData: shengxiao,
      // 复试
      fushiData: [],
      fushiTotalNum: 0,
      // 多组
      duozuData: [],
      duozuOdds: [],
      duozuItems: [],
      duozuTotalNum: 0,
      changelongActive: "1",
      kaiActive: "1",
      rowsData: [{ title: "", arr: [] }],
      title: "二连肖",
      titleId: 777,
      titleNum: 2,
      xiaowei: "xiao",
      sxArr: [],
      numArr: [],
      lianxiao2: [
        1897, 1898, 1899, 1900, 1901, 1902, 1903, 1904, 1905, 1906, 1907, 1908,
      ],
      lianxiao3: [
        1909, 1910, 1911, 1912, 1913, 1914, 1915, 1916, 1917, 1918, 1919, 1920,
      ],
      lianxiao4: [
        1921, 1922, 1923, 1924, 1925, 1926, 1927, 1928, 1929, 1930, 1931, 1932,
      ],
      lianxiao5: [
        1933, 1934, 1935, 1936, 1937, 1938, 1939, 1940, 1941, 1942, 1943, 1944,
      ],
      lianwei2: [1982, 1983, 1984, 1985, 1986, 1987, 1988, 1989, 1990, 1981],
      lianwei3: [1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 1991],
      lianwei4: [2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2001],
      lianwei5: [],
      //   复试  胆拖 多组 active
      type2: "fushi",
      labelArr1: [
        "鼠",
        "牛",
        "虎",
        "兔",
        "龙",
        "蛇",
        "马",
        "羊",
        "猴",
        "鸡",
        "狗",
        "猪",
      ],
      labelArr2: [
        "1尾",
        "2尾",
        "3尾",
        "4尾",
        "5尾",
        "6尾",
        "7尾",
        "8尾",
        "9尾",
      ],
      colorArr1: [
        ["blue", "blue", "green", "green"],
        ["red", "blue", "blue", "green"],
        ["red", "red", "blue", "blue", "green"],
        ["red", "red", "blue", "blue"],
        ["green", "red", "red", "blue"],
        ["blue", "green", "red", "red"],
        ["blue", "green", "green", "red"],
        ["red", "blue", "green", "green"],
        ["red", "red", "blue", "green"],
        ["green", "red", "red", "blue"],
        ["green", "red", "red", "blue"],
        ["blue", "green", "green", "red"],
      ],
      colorArr2: [
        ["red", "green", "green", "blue", "blue"],
        ["red", "red", "green", "green", "blue"],
        ["blue", "red", "red", "green", "green"],
        ["blue", "blue", "red", "red", "green"],
        ["green", "blue", "blue", "red", "red"],
        ["green", "green", "blue", "blue", "red"],
        ["red", "green", "green", "blue", "blue"],
        ["red", "red", "green", "green", "blue"],
        ["blue", "red", "red", "green", "green"],
        ["blue", "blue", "red", "red"],
      ],

      ballsArr2: [
        ["01", "11", "21", "31", "41"],
        ["02", "12", "22", "32", "42"],
        ["03", "13", "23", "33", "43"],
        ["04", "14", "24", "34", "44"],
        ["05", "15", "25", "35", "45"],
        ["06", "16", "26", "36", "46"],
        ["07", "17", "27", "37", "47"],
        ["08", "18", "28", "38", "48"],
        ["09", "19", "29", "39", "49"],
        ["10", "20", "30", "40"],
      ],
      // 共多少组组合
      totalNum: 0,
    };
  },
  components: {
    topTimer,
    yushe,
    yanzheng,
  },

  created() {
    this.dantuoData.forEach((item) => {
      item.disable = false;
    });
  },
  watch: {
    // 多组 注单
    duozuData: {
      deep: true,
      handler(val) {
        this.watchDuozu();
        // this.duozuTotalNum = val.length;
        // // console.log(this.duozuData, this.duozuOdds);
        // if (val.length > 0) {
        //   let zdArr = [];
        //   val.forEach((item, index) => {
        //     let obj = {
        //       label: item.join(","),
        //       num: item.join(","),
        //       title: this.title,
        //       id: this.titleId,
        //       money: this.amount,
        //       Odds: this.getMin(this.duozuOdds[index]),
        //     };
        //     zdArr.push(obj);
        //   });
        //   this.$store.commit("setXZList", zdArr);
        // } else {
        //   this.$store.commit("setXZList", []);
        // }
      },
    },
    // 胆拖 注单
    dantuoZhuDan: {
      deep: true,
      handler(val) {
        // console.log("dantuozhudan", val);
        this.totalNum = 0;
        val.forEach((item) => {
          item.forEach((ele) => {
            this.totalNum++;
          });
        });
        if (val.length > 0) {
          let zdArr = [];
          // console.log(val, this.dantuoOdds);
          val.forEach((item, index) => {
            item.forEach((ele, i) => {
              let obj = {
                label: ele.join(","),
                num: ele.join(","),
                title: this.title,
                id: this.titleId,
                money: this.amount,
                Odds: this.getMin(this.dantuoOdds[index][i]),
              };
              zdArr.push(obj);
            });
          });
          this.$store.commit("setXZList", zdArr);
        } else {
          this.$store.commit("setXZList", []);
        }
      },
    },
    amount(val) {
      if (this.type2 == "fushi") {
        this.watchData();
      } else if (this.type2 == "dantuo") {
        this.watchDanTuo();
      } else if (this.type2 == "duozu") {
        this.watchDuozu();
      }
    },
    danma() {
      if (this.danma.length > 0) {
        if (this.tuoma.length >= this.titleNum - 1) {
          this.watchDanTuo();
        } else {
          this.$store.commit("setXZList", []);
        }
      } else {
        this.$store.commit("setXZList", []);
      }
    },
    tuoma() {
      if (this.danma.length > 0) {
        if (this.tuoma.length >= this.titleNum - 1) {
          this.watchDanTuo();
        } else {
          this.$store.commit("setXZList", []);
        }
      } else {
        this.$store.commit("setXZList", []);
      }
    },
    rowsData: {
      deep: true,
      immediate: true,
      handler() {
        if (this.type2 == "fushi") {
          this.watchData();
        } else if (this.type2 == "duozu") {
          let selectedArr = this.rowsData[0].arr.filter(
            (item) => item.selected
          );
          if (selectedArr.length == this.titleNum) {
            let b = false;

            let arr1 = [];
            let arr2 = [];
            selectedArr.forEach((item) => {
              arr1.push(item.label);
              arr2.push(item.recordOdds);
            });
            if (this.duozuOdds.length > 0) {
              let newSelArr = JSON.parse(JSON.stringify(selectedArr));
              newSelArr.sort((a, b) => a.Odds - b.Odds);
              this.duozuItems.forEach((oddArr, index) => {
                let newOddArr = JSON.parse(JSON.stringify(oddArr));

                newOddArr.sort((a, b) => a.Odds - b.Odds);

                // 判断是否是每个赔率都一样  计数
                let sameCount = 0;
                newOddArr.forEach((ele, i) => {
                  if (ele.label == newSelArr[i].label) {
                    sameCount++;
                  }
                });
                // console.log(sameCount, newOddArr, newSelArr);
                if (sameCount == newOddArr.length) {
                  b = true;
                }
                // console.log(newOddArr, "yayayayaya");
              });
            }

            if (!b) {
              this.duozuItems.push(JSON.parse(JSON.stringify(selectedArr)));
              this.duozuData.push(arr1);
              this.duozuOdds.push(arr2);
            }

            this.rowsData[0].arr.forEach((item) => {
              item.selected = false;
            });
            // console.log(this.duozuData);
            this.$forceUpdate();
          }
          // console.log(selectedArr.length, this.titleNum);
        }
      },
    },
    oddData1: {
      immediate: true,
      deep: true,
      handler(resAArr) {
        // console.log(resAArr, "1111111111111111");
        //  二连肖
        let lianxiao2 = this.filterData(resAArr, 1897, 1908, this.labelArr1);
        lianxiao2.forEach((element, index) => {
          element.balls = this.ballArr[index];
          element.colors = this.colorArr1[index];
          element.disable = false;
        });
        this.lianxiao2 = lianxiao2;
        //  三连肖
        let lianxiao3 = this.filterData(resAArr, 1909, 1920, this.labelArr1);
        lianxiao3.forEach((element, index) => {
          element.balls = this.ballArr[index];
          element.colors = this.colorArr1[index];
          element.disable = false;
        });
        this.lianxiao3 = lianxiao3;
        //  四连肖
        let lianxiao4 = this.filterData(resAArr, 1921, 1932, this.labelArr1);
        lianxiao4.forEach((element, index) => {
          element.balls = this.ballArr[index];
          element.colors = this.colorArr1[index];
          element.disable = false;
        });
        this.lianxiao4 = lianxiao4;
        //  五连肖
        let lianxiao5 = this.filterData(resAArr, 1933, 1944, this.labelArr1);
        lianxiao5.forEach((element, index) => {
          element.balls = this.ballArr[index];
          element.colors = this.colorArr1[index];
          element.disable = false;
        });
        this.lianxiao5 = lianxiao5;
        //  二连尾
        let lianwei21 = this.filterData(resAArr, 1982, 1990, this.labelArr2);
        let lianwei22 = this.ddFilterData1(resAArr, [1981], ["0尾"]);
        let lianwei2 = lianwei21.concat(lianwei22);
        lianwei2.forEach((element, index) => {
          element.balls = this.ballsArr2[index];
          element.colors = this.colorArr2[index];
          element.disable = false;
        });
        this.lianwei2 = lianwei2;
        // 三连尾
        let lianwei31 = this.filterData(resAArr, 1992, 2000, this.labelArr2);
        let lianwei32 = this.ddFilterData1(resAArr, [1991], ["0尾"]);
        let lianwei3 = lianwei31.concat(lianwei32);
        lianwei3.forEach((element, index) => {
          element.balls = this.ballsArr2[index];
          element.colors = this.colorArr2[index];
          element.disable = false;
        });
        this.lianwei3 = lianwei3;
        // 四连尾
        let lianwei41 = this.filterData(resAArr, 2002, 2010, this.labelArr2);
        let lianwei42 = this.ddFilterData1(resAArr, [2001], ["0尾"]);
        let lianwei4 = lianwei41.concat(lianwei42);
        lianwei4.forEach((element, index) => {
          element.balls = this.ballsArr2[index];
          element.colors = this.colorArr2[index];
          element.disable = false;
        });
        this.lianwei4 = lianwei4;
        //  五连尾
        let labelArr5 = [
          "1尾",
          "2尾",
          "3尾",
          "4尾",
          "5尾",
          "6尾",
          "7尾",
          "8尾",
          "9尾",
        ];
        let lianwei52 = this.ddFilterData1(resAArr, [4023], ["0尾"]);
        let lianwei51 = this.filterData(resAArr, 4024, 4032, labelArr5);
        let lianwei5 = lianwei51.concat(lianwei52);
        lianwei5.forEach((element, index) => {
          element.balls = this.ballsArr2[index];
          element.colors = this.colorArr2[index];
          element.disable = false;
        });

        this.lianwei5 = lianwei5;
        // -------------------------------------------------

        this.rowsData = [
          {
            title: this.title,
            arr: JSON.parse(JSON.stringify(this.lianxiao2)),
          },
        ];
        this.dantuoData = JSON.parse(JSON.stringify(this.lianxiao2));

        // console.log(this.rowsData, "rowDataaaaa");
      },
    },
  },
  methods: {
    watchDuozu() {
      this.duozuTotalNum = this.duozuData.length;
      // console.log(this.duozuData, this.duozuOdds);
      if (this.duozuData.length > 0) {
        let zdArr = [];
        this.duozuData.forEach((item, index) => {
          let obj = {
            label: item.join(","),
            num: item.join(","),
            title: this.title,
            id: this.titleId,
            money: this.amount,
            Odds: this.getMin(this.duozuOdds[index]),
          };
          zdArr.push(obj);
        });
        this.$store.commit("setXZList", zdArr);
      } else {
        this.$store.commit("setXZList", []);
      }
    },

    // 监听胆拖
    watchDanTuo() {
      this.dantuoZhuDan = [];
      this.dantuoOdds = [];
      this.danma.forEach((dan, index) => {
        // 先把拖码 label和odds取出来
        let arr1 = [],
          arr2 = [];
        this.tuoma.forEach((tuo) => {
          arr1.push(tuo.label);
          arr2.push(tuo.recordOdds);
        });
        // 把 label和odds 排列组合一下
        let arr3 = cmn(arr1, this.titleNum - 1);
        let arr4 = cmn(arr2, this.titleNum - 1);
        // console.log(arr3, "arr333");
        //  把胆码 加到 排列组合里面 label 和odds
        arr3.forEach((item) => {
          item.unshift(dan.label);
        });
        arr4.forEach((item) => {
          item.unshift(dan.Odds);
        });
        this.dantuoZhuDan.push(arr3);
        this.dantuoOdds.push(arr4);
      });
      // console.log(this.dantuoZhuDan);
    },
    // 点击当前胆拖
    setDanTuo(item) {
      if (item.disable) {
        return;
      }
      let liItem = this.rowsData[0].arr.find(
        (element) => element.label == item.label
      );

      item.selected = !item.selected;
      item.checked = !item.checked;
      if (item.selected) {
        // liItem.disable = true;
        this.$set(liItem, "disable", true);
        this.tuoma.push(JSON.parse(JSON.stringify(item)));
      } else {
        this.$set(liItem, "disable", false);
        let index = this.tuoma.findIndex((oo) => oo.label == item.label);
        this.tuoma.splice(index, 1);
      }
      // console.log(liItem, this.rowsData[0].arr);
      this.$forceUpdate();
    },
    //   点击当前li
    setCurrentSelected(ele) {
      if (ele.disable) {
        return;
      }

      if (ele.selected) {
        ele.selected = false;
        ele.money = "";
      } else {
        if (this.type2 == "fushi") {
          let arr = this.rowsData[0].arr.filter((item) => item.selected);
          if (arr.length == 10) {
            this.$message.warning("已超出最大项目数");
            return;
          }
        }
        ele.selected = !ele.selected;
        ele.money = ele.selected ? this.amount : "";
      }
      if (this.type2 == "dantuo") {
        let dantuoItem = this.dantuoData.find(
          (item) => item.label == ele.label
        );
        if (ele.selected) {
          dantuoItem.disable = true;
          this.danma.push(JSON.parse(JSON.stringify(ele)));
          // this.danmaOdds.push(ele.Odds);
        } else {
          dantuoItem.disable = false;
          let index = this.danma.findIndex((oo) => oo.label == ele.label);
          this.danma.splice(index, 1);
          // this.danmaOdds.splice(index,1);
        }
      }

      this.$forceUpdate();
    },
    // // 最大值 4
    // Math.max.apply(Math,this.list.map(item => { return item.id }))

    // 复试  监听数据
    getMin(arr, key) {
      // 最小值 1
      return Math.min.apply(
        Math,
        arr.map((item) => {
          return item;
        })
      );
    },
    watchData() {
      let arr = this.rowsData[0].arr.filter((item) => item.selected);

      let selArr = [];
      let OddsArr = [];
      let len = arr.length;
      this.fushiData = [];
      this.fushiTotalNum = 0;
      if (len < this.titleNum) {
        this.$store.commit("setXZList", []);
      } else {
        arr.forEach((item) => {
          selArr.push(item.label);
          OddsArr.push(item.Odds);
        });
        // 正好选中的数据数量和长度一样
        if (len == this.titleNum) {
          this.fushiTotalNum = 1;
          this.fushiData = [selArr];
          let obj = {
            label: selArr.join(","),
            num: selArr.join(","),
            title: this.title,
            id: this.titleId,
            money: this.amount,
            Odds: this.getMin(OddsArr),
          };
          this.$store.commit("setXZList", [obj]);
        } else {
          // 选中的数据超过 title 的值
          let xiazhu = [];
          let cArr = cmn(selArr, this.titleNum);
          let cOdd = cmn(OddsArr, this.titleNum);
          cArr.forEach((ele, index) => {
            this.fushiTotalNum++;
            this.fushiData.push(ele);
            let obj = {
              label: ele.join(","),
              num: ele.join(","),
              title: this.title,
              id: this.titleId,
              money: this.amount,
              Odds: this.getMin(cOdd[index]),
            };
            xiazhu.push(obj);
          });

          this.$store.commit("setXZList", xiazhu);
        }
      }
    },
    changeGameType(str, id, leng) {
      this.fushiData = [];
      this.fushiTotalNum = 0;
      this.duozuItems = [];
      this.duozuData = [];
      this.duozuOdds = [];
      this.danma = [];
      this.tuoma = [];
      this.dantuoZhuDan = [];
      this.dantuoOdds = [];
      this.title = str;
      this.titleId = id;
      this.titleNum = leng;
      // this.rowsData[0].arr
      switch (str) {
        case "二连肖":
          this.xiaowei = "xiao";
          this.rowsData[0].arr = JSON.parse(JSON.stringify(this.lianxiao2));
          this.dantuoData = JSON.parse(JSON.stringify(this.lianxiao2));
          break;
        case "三连肖":
          this.xiaowei = "xiao";
          this.rowsData[0].arr = JSON.parse(JSON.stringify(this.lianxiao3));
          this.dantuoData = JSON.parse(JSON.stringify(this.lianxiao3));
          break;
        case "四连肖":
          this.xiaowei = "xiao";
          this.rowsData[0].arr = JSON.parse(JSON.stringify(this.lianxiao4));
          this.dantuoData = JSON.parse(JSON.stringify(this.lianxiao4));
          break;
        case "五连肖":
          this.xiaowei = "xiao";
          this.rowsData[0].arr = JSON.parse(JSON.stringify(this.lianxiao5));
          this.dantuoData = JSON.parse(JSON.stringify(this.lianxiao5));
          break;
        case "二连尾":
          this.xiaowei = "wei";
          console.log(this.lianwei2);
          this.rowsData[0].arr = JSON.parse(JSON.stringify(this.lianwei2));
          this.dantuoData = JSON.parse(JSON.stringify(this.lianwei2));
          break;
        case "三连尾":
          this.xiaowei = "wei";
          this.rowsData[0].arr = JSON.parse(JSON.stringify(this.lianwei3));
          this.dantuoData = JSON.parse(JSON.stringify(this.lianwei3));
          break;
        case "四连尾":
          this.xiaowei = "wei";
          this.rowsData[0].arr = JSON.parse(JSON.stringify(this.lianwei4));
          this.dantuoData = JSON.parse(JSON.stringify(this.lianwei4));
          break;
        case "五连尾":
          this.xiaowei = "wei";

          this.rowsData[0].arr = JSON.parse(JSON.stringify(this.lianwei5));
          this.dantuoData = JSON.parse(JSON.stringify(this.lianwei5));
          break;
      }

      if (this.ISOPEN) {
        this.dantuoData.forEach((item) => {
          item.Odds = item.recordOdds;
        });
        this.rowsData[0].arr.forEach((item) => {
          item.Odds = item.recordOdds;
        });
      } else {
        this.dantuoData.forEach((item) => {
          item.Odds = "--";
        });
        this.rowsData[0].arr.forEach((item) => {
          item.Odds = "--";
        });
      }

      this.dantuoData.forEach((element) => {
        element.selected = false;
        element.disable = false;
        element.checked = false;
      });
    },
    //   type2
    selectedType2(str) {
      this.fushiData = [];
      this.fushiTotalNum = 0;
      this.duozuItems = [];
      this.duozuData = [];
      this.duozuOdds = [];
      this.danma = [];
      this.tuoma = [];
      this.dantuoZhuDan = [];
      this.dantuoOdds = [];
      this.rowsData[0].arr.forEach((ele) => {
        ele.selected = false;
        ele.disable = false;
      });
      this.dantuoData.forEach((element) => {
        element.selected = false;
        element.disable = false;
        element.checked = false;
      });
      this.type2 = str;
      // if (this.isOpen) {
      //   this.rowsData[0].arr.forEach((item) => {
      //     item.Odds = item.recordOdds;
      //   });
      // } else {
      //   this.rowsData[0].arr.forEach((item) => {
      //     item.Odds = "--";
      //   });
      // }
      this.$forceUpdate();
    },
    setLongTab(n) {
      this.changelongActive = n;
    },
    setKaiTab(n) {
      this.kaiActive = n;
    },
  },
};
</script>
<style scoped>
/* custom_ul */
.listmain > .data > ul.custom_ul {
  width: 100%;
  height: 204px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex-flow: none;
}
.listmain > .data > ul.custom_ul1 {
  width: 100%;
  height: 170px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex-flow: none;
}

.listmain > .data > ul.custom_ul3 li {
  flex: 1;
}
.listmain > .data > ul.custom_ul3 .td_name {
  width: 100%;
}

.listmain .red {
  color: red;
}
em {
  font-style: normal;
}

.flex_dantuo_li {
  display: flex;
}
.flex_dantuo_li > div {
  flex: 1;
}

.listmain .data ul li {
  width: 50%;
  height: 34px;
}
</style>
